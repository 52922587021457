import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
// import { useStaticQuery, graphql } from "gatsby"
// import Swiper from 'react-id-swiper'
import "react-id-swiper/lib/styles/css/swiper.css";
import $ from "jquery"
import { Tween, Timeline } from 'react-gsap';
// import CSSPlugin from 'gsap/CSSPlugin';
import { Container, Row, Col } from 'react-bootstrap';
import { Controller, Scene } from 'react-scrollmagic';
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { TimelineLite } from "gsap/all"
import ArticleYearSlider from './articleYearSlider'
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

// const C = CSSPlugin;

const MULTIPLE_ARTICLE_QUERY = gql`
    query multipleArticleData($multiArticleYear: String!) {  
        posts(first: 100, where: {metaQuery: {metaArray: {key: "article_year", value: $multiArticleYear}}}) {
            edges {
              node {
                id
                uri
                status
                title
                is_featured
                frontline_articles_options {
                  articleYear
                  articleTitlePosition
                  backgroundColor
                  backgroundImage {
                    sourceUrl
                  }
                  featuredImage {
                    sourceUrl
                  }
                  yearPositionWithTheImage
                  yearPositionWithTheTimeline
                  yearSizeOverTheTimeline
                  imagePositionOverTimeline
                  articleTemplate
                }
              }
            }
        }
    }
`;

const MultipleArticle = ({ pageContext }) => {
    let multiArticleYear = pageContext.articleYear;

    const { data, loading, error } = useQuery(
        MULTIPLE_ARTICLE_QUERY,
        { variables: { multiArticleYear } }
    );

    useEffect(() => {
        setTimeout(function () {
            $('html, body').animate({ scrollTop: 0 }, 1000);
        }, 500);
    }, []);

    if (loading) return null;
    if (error) return <p>ERROR: {error.message}</p>;

    const articleMilestones = data.posts.edges.map((edge) => {
        return edge.node.is_featured !== 1 && <Col md={6} lg={4} key={edge.node.uri}>
            <div className="milestone-item">
                <h3 className="milestone-item-title"><AniLink fade duration={2} to={"/" + edge.node.uri} dangerouslySetInnerHTML={{ __html: edge.node.title }} ></AniLink></h3>
                <AniLink className="btn primary-btn" fade duration={2} to={"/" + edge.node.uri}>EXPLORE &gt;</AniLink>
            </div>
        </Col>
    });

    const articleFeaturedImage = data.posts.edges.map((edge, index) => {
        return edge.node.is_featured === 1 && edge.node.frontline_articles_options.backgroundImage !== null && <div className="single-article-img" style={{ backgroundImage: 'url(' + edge.node.frontline_articles_options.backgroundImage.sourceUrl + ')' }} alt={edge.node.title} key={index} />
    });

    const articleFeaturedArticle = data.posts.edges.map((edge, index) => {
        return edge.node.is_featured === 1 && <div className="featured-article-sec" key={index}>
            <h1 className="page-title">
                <AniLink fade duration={2} to={"/" + edge.node.uri} dangerouslySetInnerHTML={{ __html: edge.node.title }}></AniLink>
            </h1>
            <AniLink className="btn primary-btn" fade duration={2} to={"/" + edge.node.uri}>EXPLORE &gt;</AniLink>
        </div>
    });

    return (
        <Layout>
            <SEO title={multiArticleYear} />
            <Controller>
                <Scene
                    duration={300}
                >
                    {(progress) => (
                        <>
                            <Timeline totalProgress={progress} paused
                                target={
                                    <div>
                                        {articleFeaturedImage}
                                    </div>
                                }
                            >
                                <Tween
                                    from={{ opacity: 1 }}
                                    to={{ opacity: 0 }}
                                />
                            </Timeline>
                            <div className="single-article multiple-article-cover-wrap">
                                <div className="single-article-cover">
                                    <Container>
                                        <Row>
                                            <Col className="single-article-year-timeline" lg={{ span: 4 }} xl={{ span: 5 }}>
                                                <div className="article-timeline-verticle">
                                                    <ArticleYearSlider currentyear={multiArticleYear} />
                                                    <div className="single-article-timeline"></div>
                                                </div>
                                            </Col>
                                            <Col className="single-article-wrap mt-150" lg={{ span: 8, offset: 4 }} xl={{ span: 7, offset: 5 }}>
                                                {articleFeaturedArticle}
                                                <div className="article-milestones-sec">
                                                    <h3 className="article-milestones-sec-title">More milestones</h3>
                                                    <div className="article-milestones-list">
                                                        <Row>
                                                            {articleMilestones}
                                                        </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </>
                    )}
                </Scene>
            </Controller>
        </Layout>
    )
}

export default MultipleArticle
